//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    term: {},
    index: 0,
    revealed: false,
    trans: false,
    loaded: false,
  }),
  computed: {
    cardSize() {
      let h, w
      switch (this.$vuetify.breakpoint.name) {
        case `md`:
          w = 475
          h = 235
          break
        case `xs`:
          w = 340
          h = 235
          break
        case `sm`:
          w = 400
          h = 235
          break
        default:
          w = 475
          h = 235
      }
      return { h, w }
    },
  },
  created() {
    this.$vuetify.theme.dark = true
  },
  async beforeMount() {
    const doc = await this.$bucket.db.get(this.ids[0])
    this.term = Object.assign({}, doc)
    this.loaded = true
    this.trans = false
  },
  methods: {
    reveal() {
      umami(`Reveal letter`)
      this.revealed = true
    },
    reset(nolog) {
      !nolog && umami(`Reset letter`)
      this.revealed = false
    },
    async next() {
      this.trans = true
      if (this.index + 1 === this.ids.length) this.index = 0
      else this.index++
      umami(`Next letter`)
      await this.getTerm()
    },
    async previous() {
      if (!this.index) return
      this.trans = true
      this.index--
      umami(`Previous letter`)
      await this.getTerm()
    },
    getTerm() {
      this.reset(1)
      Promise.all([
        this.$bucket.db.get(this.ids[this.index]).then(doc => {
          this.term = Object.assign({}, doc)
        }),
        new Promise(resolve => setTimeout(resolve, 275)),
      ]).then(() => (this.trans = false))
    },
  },
}
